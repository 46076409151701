import { Injectable, Injector, inject } from '@angular/core';
import { ContextInfo, ContextTree } from '../../system/context/context.model';
import { Subject } from 'rxjs';
import { JsToNgService } from './js-to-ng.service';
import { MenuModel } from '../../models/menu';

export interface DocumentDialogData {
  contractId: number;
  moduleId: number;
  moduleCode: string;
  moduleName: string;
  entityInstanceId: number;
  [key: string]: any;
}

@Injectable({
  providedIn: 'root',
})
export class NgToJsService {
  refreshingContext = new Subject<boolean>();
  bridge = inject(JsToNgService);
  private angularJsServiceInstance: any;

  constructor(private injector: Injector) {}

  private get angularJsService() {
    if (!this.angularJsServiceInstance) {
      this.angularJsServiceInstance = this.injector.get('AngularJsUserService', null);
      if (!this.angularJsServiceInstance) {
        throw new Error('AngularJs service is not available.');
      }
    }
    return this.angularJsServiceInstance;
  }

  changeContext(context: ContextInfo, addLastUsedContract: boolean = true) {
    this.angularJsService.changeContext(context, addLastUsedContract);
  }

  contextDataLoaded(contextTree: ContextTree) {
    this.angularJsService.setContextTree(contextTree);
  }

  goToVoid() {
    this.angularJsService.goToVoid();
  }

  /* 
  The following methods are used to open a dialog from angularJs.
  To add a new method, you need to add a new method in the angular-js.service.js, and call it from here.
  Also, there is possibility to listen for the dialog close event (even send dialogData) with an effect on userStore.
  To achieve this, you need to call:
        dialog.show(...).then(() => jsToNg.dialogClosed(_YOUR_EXPECTED_DATA_))

  example of effect:
        dialogEffect = effect(() => {
            const dialogData = this.userService().dialogData();
            if (dialogData == _YOUR_EXPECTED_DATA_) {
                ...do something,
            }
        });
   */
  editContract() {
    this.angularJsService.editContract();
  }

  openDocument(document: DocumentDialogData) {
    this.angularJsService.openDocument(document);
  }

  openModuleSettings(module: MenuModel) {
    this.angularJsService.openModuleSettings(module);
  }
}
